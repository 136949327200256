.spinner__wrapper {
  width: 100%;
  height: 100%;
  z-index: 21;
  min-height: inherit;
  //position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: #ffffffa6;
  .spinner-border {
    width: 3rem;
    height: 3rem;
    position: absolute;
    top: 40%;
    left: 50%;
  }
}
